<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="username">Username</label>
                    <InputText id="username" v-model="forms.username" required="true" autofocus :class="{ 'p-invalid': errorAdd.userweb_username }" />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.userweb_username" >{{ errorAdd.userweb_username[0] }}</small >
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="name">Nama</label>
                    <InputText id="name" v-model="forms.nama" required="true" autofocus :class="{ 'p-invalid': errorAdd.userweb_nama }" />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.userweb_nama" >{{ errorAdd.userweb_nama[0] }}</small>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="level_id">Level</label>
                    <Dropdown id="level_id" v-model="forms.level_id" :loading="loadingDropdownLevel" :options="dataDropdownLevel" :class="{ 'p-invalid': errorAdd.level_id }" optionLabel="level_nama" optionValue="level_id" placeholder="Pilih Level" :filter="true" :showClear="true" @filter="searchDropdownLevel($event ,'add')"/>
                    <small class="p-invalid" style="color: red" v-if="errorAdd.level_id" >{{ errorAdd.level_id[0] }}</small>
                </div>
            </div>
        </div>

        <Button :loading="loading" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
    </div>
</template>


<script>
import moment from 'moment';
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownLevel: false,

            // dataDropdown
            dataDropdownLevel: null,

            // addNew
            forms: {
                username: null,
                name: null,
                level_id: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownLevel('');
    },
    watch: {

    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DROPDOWN
        searchDropdownLevel(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dlevel.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownLevel = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/level-user-web',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {
                    
                    if(purpose == "add"){
                        this.dataDropdownLevel = res.data.data;
                        this.loadingDropdownLevel = false;
                    }else if(purpose == null){
                        this.dataDropdownLevel = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-userweb/create',
                data: {
                    "userweb_username": this.forms.username,
                    "userweb_nama": this.forms.nama,
                    "level_id": this.forms.level_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.username = null;
            this.forms.nama = null;
            this.forms.level_id = null;
        },
    }
}
</script>
