<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Level</label>
                    <Dropdown v-model="filters.level_id" :loading="loadingDropdownLevel" :options="dataDropdownLevel" optionLabel="level_nama" optionValue="level_id" placeholder="Pilih Level" :filter="true" :showClear="true" @filter="searchDropdownLevel($event, 'filter')"/>
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownLevel: false,

            // dataDropdown
            dataDropdownLevel: null,

            // filter
            filters: {
                level_id: null,
            },
        }
    },
    mounted() {
        this.searchDropdownLevel('');
    },
    watch: {
    },
    computed:{
    },
    methods: {
        // DROPDOWN
        searchDropdownLevel(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dlevel.filterValue = valueEdit;
                }

                if(purpose == "filter"){
                    this.loadingDropdownLevel = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/level-user-web',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "filter"){
                        this.dataDropdownLevel = res.data.data;
                        this.loadingDropdownLevel = false;
                    }else if(purpose == null){
                        this.dataDropdownLevel = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        getDataTable(){
            this.$emit('submit');
        }
    }
}
</script>

