<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
        <div class="field">
            <label for="Username">Username</label>
            <InputText id="username" v-model="item.userweb_username" required="true" autofocus :class="{ 'p-invalid': errorEdit.userweb_username }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.userweb_username" >{{ errorEdit.userweb_username[0] }}</small >
        </div>
        <div class="field">
            <label for="nama">Nama</label>
            <InputText id="nama" v-model="item.userweb_nama" required="true" autofocus :class="{ 'p-invalid': errorEdit.userweb_nama }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.userweb_nama" >{{ errorEdit.userweb_nama[0] }}</small >
        </div>
        <div class="field">
            <label for="level_id">Level</label>
            <Dropdown id="level_id" ref="dlevel" v-model="item.level_id" :loading="loadingDropdownLevel" :options="dataDropdownLevel" :class="{ 'p-invalid': errorEdit.level_id }" optionLabel="level_nama" optionValue="level_id" placeholder="Pilih Level" :filter="true" :showClear="true" @filter="searchDropdownLevel($event,'edit')"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.level_id" >{{ errorEdit.level_id[0] }}</small>
        </div>
        <template #footer>
            <Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownLevel: false,

            // dataDropdown
            dataDropdownLevel: null,

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
        this.searchDropdownLevel('');
    },
    computed:{
        ...mapGetters(['errorEdit']),
    },
    methods: {
        // DROPDOWN
        searchDropdownLevel(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dlevel.filterValue = valueEdit;
                }

                if(purpose == "edit"){
                    this.loadingDropdownLevel = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/level-user-web',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "edit"){
                        this.dataDropdownLevel = res.data.data;
                        this.loadingDropdownLevel = false;
                    }else if(purpose == null){
                        this.dataDropdownLevel = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-userweb/update',
                data: {
                    "userweb_id" : this.item.userweb_id,
                    "userweb_username" : this.item.userweb_username,
                    "userweb_nama" : this.item.userweb_nama,
                    "level_id" : this.item.level_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diperbarui', life: 3000,});
            });
		},
    }
}
</script>